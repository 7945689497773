<template>
  <div class="ant-spin-nested-loading">
    <div class="spin-container" v-if="loading">
      <a-spin/>
    </div>
    <div class="ant-spin-container" :class="{ 'ant-spin-blur': loading }">
      <table class="ant-table ant-table-with-headers">
        <thead class="ant-table-thead">
        <tr v-if="compactMode" style="display:none;">
          <th></th>
          <th></th>
        </tr>
        <tr v-else>
          <th class="ant-table-selection-column text-left" v-if="rowSelection" :style="{ width: '60px' }">
            <a-checkbox v-model:checked="checkAll"
                        :indeterminate="checkAllIndeterminate"
                        @change="onCheckAllChange"/>
          </th>
          <th>Объект</th>
          <th>Код</th>
          <th v-if="!hideType">Тип</th>
          <th class="text-right ant-table-row-cell-last" :style="{ width: '100px' }">
            <slot name="actionsTitle"></slot>
          </th>
        </tr>
        </thead>
        <template v-if="dataSource && dataSource.length > 0">
          <tbody class="ant-table-tbody" v-for="(group, g) in dataSource" :key="g">
          <tr class="ant-table-row header">
            <td :colspan="compactMode ? 2 : 5">
              <a-breadcrumb>
                <template #separator>
                  <right-outlined :style="{ fontSize: '0.75rem' }"/>
                </template>
                <a-breadcrumb-item v-for="record in group.path" :key="record.id">
                  <router-link v-if="breadcrumbLinks" :to="{ name: breadcrumbLinks, params: { id: record.id } }">
                    {{ record.name === 'root' ? 'Верхний уровень' : record.name }}
                  </router-link>
                  <span v-else>{{ record.name === 'root' ? 'Верхний уровень' : record.name }}</span>
                </a-breadcrumb-item>
              </a-breadcrumb>
            </td>
          </tr>
          <tr :class="`ant-table-row ${rowClass}`" v-for="record in group.objects" :key="record.id">
            <template v-if="compactMode">
              <td>{{ record.name }}</td>
              <td class="text-right">
                <slot name="actions" v-bind="{ record }"></slot>
              </td>
            </template>
            <template v-else>
              <td v-if="rowSelection">
                <a-checkbox :checked="rowSelectionState[record.id]" @change="onRowChecked($event, record.id)"/>
              </td>
              <td>
                <router-link class="text-color-text"
                             :to="{ name: record.hasChildren ? 'objectChildren' : 'objectView', params: { id: record.id } }">
                  {{ record.name }}
                </router-link>
              </td>
              <td class="text-break">{{ record.code }}</td>
              <td v-if="!hideType">{{ record.objectType?.name }}</td>
              <td class="text-right">
                <slot name="actions" v-bind="{ record }"></slot>
              </td>
            </template>
          </tr>
          </tbody>
        </template>
        <template v-else>
          <tbody>
          <tr>
            <td :colspan="compactMode ? 2 : 5" class="text-center" :style="{ padding: '1rem' }">
              <p class="disabled-color-text">Ничего не найдено</p>
            </td>
          </tr>
          </tbody>
        </template>
      </table>

      <a-pagination class="ant-table-pagination" v-bind="pagination" show-less-items @change="onPageChange"
                    :size="compactMode ? 'small' : ''"/>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import {
  RightOutlined
} from '@ant-design/icons-vue';
import { onMounted, watch } from "@vue/runtime-core";

export default {
  name: 'ObjectSearchTable',
  props: {
    dataSource: Array,
    loading: Boolean,
    rowSelection: Object, // { selectedRowKeys, onChange(keys) }
    compactMode: Boolean,
    breadcrumbLinks: String,
    pagination: Object, // { hideOnSinglePage, pageSize: 10, total: 22, current: 1 }
    rowClass: { type: String, default: 'row-show-select-btn' },
    hideType: Boolean,
  },
  emits: ['change'],
  setup(props, { emit }) {
    const rowSelectionState = ref({});
    const checkAll = ref(false);
    const checkAllIndeterminate = ref(false);
    const rowSelectionSelectedRowKeys = computed(() => props.rowSelection?.selectedRowKeys || []);

    onMounted(async () => {
      props.dataSource.forEach((group) => {
        group.objects.forEach((record) => {
          rowSelectionState.value[record.id] = false;
        })
      });
      if (props.rowSelection) {
        props.rowSelection.selectedRowKeys.forEach((valueStr) => {
          rowSelectionState.value[valueStr] = true;
        });
      }
    });

    function onRowChecked(e, key) {
      rowSelectionState.value[key] = e.target?.checked;
      props.rowSelection.onChange(calcSelectedState());
    }

    function calcSelectedState() {
      const emitArr = [];
      let selected = 0;
      let total = 0;
      for (const [key, value] of Object.entries(rowSelectionState.value)) {
        if (value) {
          emitArr.push(key);
          selected++
        }
        total++;
      }

      // Для чек-бокса (выбрать всё)
      checkAllIndeterminate.value = false;
      if (selected === 0) {
        checkAll.value = false;
      } else if (selected === total) {
        checkAll.value = true;
      } else {
        checkAllIndeterminate.value = true;
      }
      return emitArr;
    }

    function onCheckAllChange(e) {
      checkAllIndeterminate.value = false;
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(rowSelectionState.value)) {
        rowSelectionState.value[key] = e.target.checked;
      }
    }

    function onPageChange(page) {
      emit('change', { current: page });
    }

    watch(rowSelectionSelectedRowKeys, () => {
      // Обновить чекбоксы в соотвествие с обновленными свойствами props
      console.log(rowSelectionSelectedRowKeys, rowSelectionState.value);
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(rowSelectionState.value)) {
        rowSelectionState.value[key] = false;
      }
      rowSelectionSelectedRowKeys.value.forEach((valueStr) => {
        rowSelectionState.value[valueStr] = true;
      });
      calcSelectedState();
    });

    return {
      rowSelectionState, onRowChecked,
      checkAll, checkAllIndeterminate, onCheckAllChange,
      onPageChange,
    }
  },
  components: { RightOutlined }
}
</script>

<style lang="less">
.ant-table {
  &.ant-table-with-headers {
    width: 100%;

    .ant-table-row {
      &.header {
        .ant-breadcrumb-link {
          color: @grayscale-gray;
          font-size: 14px;
        }
      }
    }
  }
}

.spin-container {
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: 400px;

  .ant-spin {
    top: 50%;
    left: 50%;
    margin: -10px -10px 0 0;
  }
}

.ant-table-pagination {
  &.mini {
    margin: 0;
    padding: 1rem 0.5rem;
  }
}
</style>
