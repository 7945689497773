<template>
  <section>

    <a-breadcrumb
        v-if="!loadingPreloading && pageTitle !== 'Структура'">
      <a-breadcrumb-item v-for="(pathItem) in objectPath" :key="pathItem.id">
        <router-link v-if="pathItem.name === 'root'" :to="{ name: 'objects' }">Верхний уровень</router-link>
        <router-link v-else :to="{ name: 'objects', params: { id: pathItem.id } }">{{ pathItem.name }}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{ pageTitle }}</a-breadcrumb-item>
    </a-breadcrumb>

    <a-page-header :title="pageTitle" :class="{ loading: loadingPreloading, 'hide-back': !backRoute }"
                   @back="!!backRoute
                      ? $router.push(backRoute?.name === 'root'
                        ? { name: 'objects' }
                        : { name: 'objects', params: { id: backRoute?.id } })
                      : false">
      <template #extra>
        <a-button size="large" type="danger" v-if="currentNodeInfo.name === 'root' && !currentNodeInfo.mapUrl">
          <router-link :to="{ name: 'objectsSvgMap'}">
            Добавьте SVG-карту корня
          </router-link>
        </a-button>

        <search-expandable size="large" placeholder="Поиск по названию и коду объекта"
                           v-model="search" @enter="fetchList"/>
        <a-dropdown :trigger="['click']">
          <template #overlay>
            <a-menu>
              <template v-if="currentNodeInfo.name === 'root'">
                <a-menu-item key="add_root" @click="openModalCreateNewObject()">
                  <plus-circle-outlined/>
                  Добавить объект в корень
                </a-menu-item>
                <a-menu-item key="map">
                  <router-link :to="{ name: 'objectsSvgMap'}">
                    <environment-outlined/>
                    SVG-карта корня
                  </router-link>
                </a-menu-item>
              </template>
              <template v-else>
                <a-menu-item key="edit">
                  <router-link :to="{ name: 'objectView', params: { id: currentNodeInfo.id } }">
                    <edit-outlined/>
                    Редактировать
                  </router-link>
                </a-menu-item>
                <a-menu-item key="add_child" @click="openModalCreateNewObject(currentNodeInfo.id)">
                  <plus-circle-outlined/>
                  Добавить дочерний объект
                </a-menu-item>
                <a-menu-item key="book">
                  <router-link :to="{ name: 'objectView', params: { id: currentNodeInfo.id }, hash: '#reservations' }">
                    <calendar-outlined/>
                    Бронирование
                  </router-link>
                </a-menu-item>
                <a-menu-item key="move" @click="openModalMoveToSection([currentNodeInfo.id])">
                  <upload-outlined/>
                  В другой раздел
                </a-menu-item>
              </template>
            </a-menu>
          </template>
          <a-button key="more" size="large">
            <template #icon>
              <more-outlined/>
            </template>
          </a-button>
        </a-dropdown>
      </template>
    </a-page-header>

    <main v-if="loadingPreloading">
      <a-skeleton/>
    </main>
    <main v-else>

      <!-- Таблица в режиме поиска -->
      <object-search-table
          v-if="dataMode === 'search'"
          :data-source="data" :loading="loading" breadcrumb-links="objects"
          :row-selection="{ selectedRowKeys, onChange: (keys) => selectedRowKeys = keys }"
          :pagination="pagination" @change="handleTableChange">
        <template #actionsTitle>
          <a-button key="move_to_section" size="small" :disabled="selectedRowKeys.length === 0"
                    @click="openModalMoveToSection(selectedRowKeys)">
            <upload-outlined/>
          </a-button>
          <a-button key="delete_selected" size="small" :disabled="selectedRowKeys.length === 0"
                    @click="handleDeleteChecked">
            <delete-filled/>
          </a-button>
        </template>
        <template #actions="{ record }">
          <a-dropdown :trigger="['click']" v-if="record.id !== 'load-more'">
            <template #overlay>
              <a-menu>
                <a-menu-item key="edit">
                  <router-link :to="{ name: 'objectView', params: { id: record.id } }">
                    <edit-outlined/>
                    Редактировать
                  </router-link>
                </a-menu-item>
                <a-menu-item key="add_child" @click="openModalCreateNewObject(record.id)">
                  <plus-circle-outlined/>
                  Добавить дочерний объект
                </a-menu-item>
                <a-menu-item key="book">
                  <router-link :to="{ name: 'objectView', params: { id: record.id }, hash: '#reservations' }">
                    <calendar-outlined/>
                    Бронирование
                  </router-link>
                </a-menu-item>
                <a-menu-item key="move" @click="openModalMoveToSection([record.id])">
                  <upload-outlined/>
                  В другой раздел
                </a-menu-item>
                <a-menu-item key="delete" @click="handleDelete(record.id)" v-if="!record.hasChildren">
                  <delete-outlined/>
                  Удалить
                </a-menu-item>
              </a-menu>
            </template>
            <a-button key="more" type="dashed" size="large">
              <more-outlined/>
            </a-button>
          </a-dropdown>
        </template>
      </object-search-table>
      <!-- Таблица обычного древовидного режима -->
      <a-table
          v-else
          :columns="columns" :data-source="data" :loading="loading" row-key="id" :pagination="pagination"
          :expanded-row-keys="expandedRowKeys"
          :row-selection="{ selectedRowKeys, onChange: (keys) => selectedRowKeys = keys }"
          :row-class-name="(record) => (record.id === 'load-more' ? 'row-load-more' : null)"
          @change="handleTableChange" @expand="onExpandChange"
          :locale="{ emptyText: 'Нет данных' }">
        <template #expandIcon="{ expanded, record, onExpand, expandable }">
          <span :style="{ marginRight: '0.5rem' }">
            <loading-outlined v-if="record.loading"/>
            <right-outlined v-else-if="expandable || record.hasChildren"
                            :rotate="expanded ? 90 : 0"
                            @click="onExpand(record)"
            />
          </span>
        </template>
        <template #name="{ record }">
          <a class="navy-blue-color-text" v-if="record.id === 'load-more'" @click="loadMoreChildren(record)">Ещё...</a>
          <router-link class="text-color-text" v-else
                       :to="{ name: record.hasChildren ? 'objectChildren' : 'objectView', params: { id: record.id } }">
            {{ record.name }}
          </router-link>
        </template>
        <template #objectType="{ record }">
          {{ record.objectType?.name }}
        </template>
        <template #actions="{ record }">
          <div v-if="record.id !== 'load-more'" :style="{ width: '130px' }">

            <a-button class="sort-item" key="sort_up" type="dashed" size="small" @click="sortElem(record, -1)">
              <up-outlined class="light-gray-color-text"/>
            </a-button>

            <a-button class="sort-item" key="sort_down" type="dashed" size="small" @click="sortElem(record, +1)"
                      :style="{ margin: '0 0.5rem 0 1rem' }">
              <down-outlined class="light-gray-color-text"/>
            </a-button>

            <a-dropdown :trigger="['click']">
              <template #overlay>
                <a-menu>
                  <a-menu-item key="edit">
                    <router-link :to="{ name: 'objectView', params: { id: record.id } }">
                      <edit-outlined/>
                      Редактировать
                    </router-link>
                  </a-menu-item>
                  <a-menu-item key="add_child" @click="openModalCreateNewObject(record.id)">
                    <plus-circle-outlined/>
                    Добавить дочерний объект
                  </a-menu-item>
                  <a-menu-item key="book">
                    <router-link :to="{ name: 'objectView', params: { id: record.id }, hash: '#reservations' }">
                      <calendar-outlined/>
                      Бронирование
                    </router-link>
                  </a-menu-item>
                  <a-menu-item key="move" @click="openModalMoveToSection([record.id])">
                    <upload-outlined/>
                    В другой раздел
                  </a-menu-item>
                  <a-menu-item key="move" @click="handleDelete(record.id)" v-if="!record.hasChildren">
                    <delete-outlined/>
                    Удалить
                  </a-menu-item>
                </a-menu>
              </template>
              <a-button key="more" type="dashed" size="large">
                <more-outlined/>
              </a-button>
            </a-dropdown>
          </div>
        </template>
        <template #actionsTitle>
          <a-button key="move_to_section" size="small" :disabled="selectedRowKeys.length === 0"
                    @click="openModalMoveToSection(selectedRowKeys)">
            <upload-outlined/>
          </a-button>
          <a-button key="delete_selected" size="small" :disabled="selectedRowKeys.length === 0"
                    @click="handleDeleteChecked">
            <delete-filled/>
          </a-button>
        </template>
      </a-table>

    </main>

    <!-- Создание нового типа объекта -->
    <a-modal v-model:visible="modalAddShow" title="Создать объект" @ok="modalAddShow = false">
      <a-form
          ref="refForm"
          :model="formInfo"
          layout="vertical"
      >
        <a-form-item label="Код *" name="code" :rules="[
            { required: true, message: 'Введите код', trigger: 'blur' },
            { pattern: /^[a-zA-z\d\w-]+$/, message: 'Доступны только латинские буквы, цифры и тире', trigger: 'blur' },
            { min: 1, max: 255, message: 'Длина должна быть от 1 до 255 символов', trigger: 'blur' }
          ]">
          <a-input v-model:value="formInfo.code" placeholder="Введите код объекта">
            <template #suffix>
              <a-tooltip title="Получить уникальный код">
                <span class="cursor-pointer" @click="getUniqueCode">
                  <reload-outlined/>
                </span>
              </a-tooltip>
            </template>
          </a-input>
        </a-form-item>
        <a-form-item label="Название *" name="name" :rules="[
            { required: true, message: 'Введите название', trigger: 'blur' },
            { min: 1, max: 255, message: 'Длина должна быть от 1 до 255 символов', trigger: 'change' }
          ]">
          <a-input v-model:value="formInfo.name" placeholder="Введите название"/>
        </a-form-item>
        <a-form-item label="Тип объекта *" name="objectTypeId" :rules="[
            { required: true, message: 'Выберите тип объекта', trigger: 'blur' },
          ]">
          <a-select v-model:value="formInfo.objectTypeId"
                    show-search
                    :default-active-first-option="false"
                    :show-arrow="false"
                    :filter-option="false"
                    :not-found-content="null"
                    @search="getObjectTypes"
                    placeholder="Выберите тип объекта" size="large">
            <a-select-option :value="option.id" v-for="(option) in objectTypesData" :key="option.id">
              {{ option.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template #footer>
        <a-button key="cancel" size="large" @click="modalAddShow = false">Отменить</a-button>
        <a-button key="submit" size="large" type="primary" :loading="modalAddLoading"
                  :disabled="!formInfo.code || !formInfo.name || !formInfo.objectTypeId"
                  @click="submitCreate">
          Сохранить
        </a-button>
      </template>
    </a-modal>

    <!-- Перемещение в раздел -->
    <object-move-modal v-model:visible="modalMoveShow" :selected-items="modalMoveSelectedIds"
                       @proceed="moveToSectionProceedHandler"/>

  </section>
</template>

<script>
import { getListData, getOneData, requestAPI } from "@/compositions/objects";
import { onMounted, watch } from "@vue/runtime-core";
import {
  MoreOutlined,
  EditOutlined,
  CalendarOutlined,
  UploadOutlined,
  PlusCircleOutlined, ReloadOutlined,
  RightOutlined, UpOutlined, DownOutlined,
  EnvironmentOutlined,
  DeleteFilled,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons-vue';
import SearchExpandable from "@/components/common/SearchExpandable";
import { computed, ref, unref } from "@vue/reactivity";
import { getListData as getObjectTypesData } from "@/compositions/objectTypes";
import { message, Modal, notification } from "ant-design-vue";
import { useRoute, useRouter } from "vue-router";
import ObjectSearchTable from "../../../components/admin/objects/ObjectSearchTable";
import ObjectMoveModal from "@/components/admin/objects/ObjectMoveModal";
import { numberHelper, urlHelper } from "@/compositions/commonFunctions";

export default {
  name: "ObjectsListPage",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const loadingPreloading = ref(true);
    const pageTitle = ref('');
    const objectPath = ref([]);
    const backRoute = computed(() => unref(objectPath)?.slice(-1).pop());

    const {
      loading, parentId,
      columns, data, dataMode,
      selectedRowKeys, loadMoreKeys,
      search, handleTableChange, loadMoreChildren,
      fetchList, pagination,
      expandedRowKeys, onExpandChange,
    } = getListData();
    const { formInfo, resetFormInfo } = getOneData();
    const {
      updateObject,
      removeObject,
      massUpdateObjects,
      massSortObjects,
      getObjectById,
      generateUniqueCode
    } = requestAPI();
    const currentNodeInfo = ref({});

    const {
      fetchList: fetchObjectTypes,
      data: objectTypesData,
      search: objectTypesSearch,
    } = getObjectTypesData();

    async function getObjectTypes(search) {
      objectTypesSearch.value = search;
      fetchObjectTypes();
    }

    const modalAddShow = ref(false);
    const modalAddLoading = ref(false);
    const refForm = ref({});
    //
    const modalMoveShow = ref(false);
    const modalMoveSelectedIds = ref([]);
    const routeParamId = computed(() => route?.params?.id);

    onMounted(() => {
      loadData();
    });

    watch(routeParamId, () => {
      if (route?.name === 'objects' || route?.name === 'objectChildren') {
        search.value = '';
        loadData();
      }
    });

    async function loadData() {
      parentId.value = route.params?.id || 'root';

      const res = await getObjectById(route.params?.id || 'root');
      currentNodeInfo.value = res;
      objectPath.value = res?.path || [];
      pageTitle.value = res?.name && res?.name !== 'root' ? res?.name : 'Структура';
      urlHelper.setPageTitle(pageTitle.value);

      await fetchList();
      await fetchObjectTypes();
      loadingPreloading.value = false;
    }

    async function submitCreate() {
      return refForm.value.validate()
          .then(async () => {
            modalAddLoading.value = true;
            const requestForm = unref(formInfo);
            const res = await updateObject(requestForm);
            if (res?.id) {
              router.push({ name: 'objectView', params: { id: res?.id } });
            } else {
              throw TypeError('Ошибка сохранения объекта');
            }
          })
          .catch(error => {
            if (error?.errorFields) {
              error.errorFields.forEach(errorField => errorField.errors.forEach(errorType => message.error(errorType)));
            } else {
              console.error('Ошибка отправки данных');
            }
          })
          .finally(() => {
            modalAddLoading.value = false;
          });
    }

    async function openModalCreateNewObject(parentId) {
      formInfo.value.parentId = parentId;
      modalAddShow.value = true;
    }

    async function getUniqueCode() {
      const code = await generateUniqueCode();
      if (code) {
        formInfo.value.code = code;
      } else {
        formInfo.value.code = numberHelper.uuid();
      }
      try {
        refForm.value.clearValidate('code');
      } catch (e) {
        console.error(e);
      }
    }

    function openModalMoveToSection(recordIds) {
      if (recordIds) {
        console.log(recordIds);
        modalMoveSelectedIds.value = recordIds;
        modalMoveShow.value = true;
      } else {
        notification.info({
          message: 'Выберите объекты',
          description: 'Отметьте хотя бы один объект для перемещения'
        });
      }
    }

    async function moveToSectionProceedHandler(dstId) {
      modalMoveShow.value = false;
      const reqArr = [];
      modalMoveSelectedIds.value.forEach((id) => reqArr.push({ id, parentId: dstId }));
      // Перемещаем в выбранные разделы
      const { unpassedIds, passedIds } = await massUpdateObjects(reqArr);
      if (unpassedIds.length > 0) {
        if (passedIds.length > 0) {
          notification.info({
            message: 'Не все объекты были перемещены',
            description: `Найден зацикленный маршрут перемещения. Успешно перемещено ${passedIds.length} из ${reqArr.length}`,
          });
        } else {
          notification.error({
            message: 'Перемещение не удалось',
            description: `Найден зацикленный маршрут перемещения.`,
          });
        }
      } else {
        notification.success({
          message: 'Успех',
          description: reqArr.length > 1 ? 'Объекты успешно перемещены' : 'Объект успешно перемещён'
        });
      }

      await fetchList();
      modalMoveSelectedIds.value = [];
      selectedRowKeys.value = [];
    }

    async function sortElem(record, offset) {
      const reqArr = [{ id: record.id, offset }];
      await massSortObjects(record.parentId, reqArr);
      await fetchList();
    }

    async function handleDelete(id) {
      Modal.confirm({
        title: 'Удалить объект?',
        okText: 'Удалить',
        okType: 'danger',
        cancelText: 'Отмена',
        async onOk() {
          await removeObject(id);
          notification.success({
            message: 'Успех',
            description: 'Объект успешно удалён',
          });
          await fetchList();
        },
      });
    }

    async function handleDeleteChecked() {
      Modal.confirm({
        title: 'Удалить выбранные объекты?',
        content: `Выбрано (${unref(selectedRowKeys).length})`,
        okText: 'Удалить',
        okType: 'danger',
        cancelText: 'Отмена',
        async onOk() {
          let successCnt = 0;
          for (let id of unref(selectedRowKeys)) {
            const res = await removeObject(id);
            if (res?.id) successCnt++;
          }
          if (successCnt === 0) {
            return Modal.info({
              title: 'Удаление невозможно',
              content: 'У выбранных объектов есть дочерние элементы',
              okText: 'Понятно',
              okType: 'default',
            });
          }
          if (successCnt < unref(selectedRowKeys).length) {
            notification.info({
              message: 'Не все объекты были удалены',
              description: `Некоторые объекты содержат дочерние элементы. Успешно удалено ${successCnt} из ${unref(selectedRowKeys).length}`,
            });
          } else {
            notification.success({
              message: 'Успех',
              description: `Выбранные объекты успешно удалены`,
            });
          }
          selectedRowKeys.value = [];
          fetchList();
        },
      });
    }

    watch(modalAddShow, (close, open) => {
      // Если закрываем, очищаем форму
      if (close && !open) {
        try {
          resetFormInfo();
          refForm.value.clearValidate();
          getObjectTypes('');
        } catch (e) {
          console.error(e);
        }
      }
    });

    return {
      loading, loadingPreloading, pagination, pageTitle, objectPath, backRoute,
      search, columns, data, selectedRowKeys, loadMoreKeys, handleTableChange, fetchList, dataMode,
      formInfo, refForm, currentNodeInfo, expandedRowKeys, onExpandChange, loadMoreChildren,
      modalAddShow, modalAddLoading, submitCreate, openModalCreateNewObject, getUniqueCode,
      modalMoveShow, modalMoveSelectedIds, openModalMoveToSection, moveToSectionProceedHandler, sortElem,
      objectTypesData, objectTypesSearch, getObjectTypes, handleDeleteChecked, handleDelete
    };
  },
  components: {
    ObjectMoveModal, ObjectSearchTable, SearchExpandable,
    MoreOutlined,
    EditOutlined,
    CalendarOutlined,
    UploadOutlined, ReloadOutlined,
    PlusCircleOutlined, EnvironmentOutlined, DeleteFilled, RightOutlined, UpOutlined, DownOutlined, DeleteOutlined,
    LoadingOutlined,
  }
}
</script>

<style lang="less">
.sort-item {
  opacity: 0;
}

.ant-table-row:hover {
  .sort-item {
    opacity: 1;
  }
}
</style>
