// API запросы
import Axios from "axios";
import { urlHelper } from "@/compositions/commonFunctions";
import { store } from "@/store";
import { reactive, ref, unref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

export function requestAPI() {

  // Получение списка объектов
  async function fetchListObjects(id, params) {
    try {
      return await Axios.get(`/objects/${id}/children` + urlHelper.makeGetParams(params));
    } catch (e) {
      store.commit('setError', e, { root: true })
    }
  }

  async function searchListObjects(params) {
    try {
      return await Axios.get(`/objects` + urlHelper.makeGetParams(params));
    } catch (e) {
      store.commit('setError', e, { root: true })
    }
  }

  // Получение единичного объекта
  async function getObjectById(id, params) {
    try {
      return await Axios.get(`/objects/${id}` + urlHelper.makeGetParams(params));
    } catch (e) {
      store.commit('setError', e, { root: true })
    }
  }

  // Создание объектов / Обновление объектов по ID
  async function updateObject(formData, id = null, params) {
    try {
      if (id) {
        return await Axios.put(`/objects/${id}` + urlHelper.makeGetParams(params), { ...formData });
      } else {
        return await Axios.post(`/objects` + urlHelper.makeGetParams(params), { ...formData });
      }
    } catch (e) {
      store.commit('setError', e, { root: true })
    }
  }

  // Создание объектов / Обновление объектов по ID
  async function massUpdateObjects(objects = []) {
    try {
      return await Axios.put(`/objects`, objects);
    } catch (e) {
      store.commit('setError', e, { root: true })
    }
  }

  // Сортировка объекта
  async function massSortObjects(parentId, payload) {
    try {
      return await Axios.post(`/objects/${parentId}/sort`, payload);
    } catch (e) {
      store.commit('setError', e, { root: true })
    }
  }

  // Удаление типа объектов по ID
  async function removeObject(id) {
    try {
      return await Axios.delete(`/objects/${id}`,);
    } catch (e) {
      store.commit('setError', e, { root: true })
    }
  }

  async function getObjectBreadcrumbs(id) {
    try {
      return await Axios.get(`/objects/${id}/map-breadcrumbs`);
    } catch (e) {
      store.commit('setError', e, { root: true })
    }
  }

  async function generateQRCode(id) {
    try {
      return await Axios.post(`/objects/${id}/qr-code`);
    } catch (e) {
      store.commit('setError', e, { root: true })
    }
  }

  async function generateUniqueCode() {
    try {
      return (await Axios.get(`/service/code`))?.code + '';
    } catch (e) {
      store.commit('setError', e, { root: true })
    }
  }

  // Получение политики бронирования объекта
  async function getObjectReservationPolicy(objectId, params) {
    if (!objectId) return false;
    try {
      return await Axios.get(`/objects/${objectId}/policy` + urlHelper.makeGetParams(params));
    } catch (e) {
      store.commit('setError', e, { root: true })
    }
  }

  // Создание и обновление политики бронирования объекта
  async function updateReservationPolicy(formData, objectId, policyId = null) {
    try {
      if (policyId) {
        return await Axios.put(`/objects/${objectId}/policy/${policyId}`, { ...formData });
      } else {
        return await Axios.post(`/objects/${objectId}/policy`, { ...formData });
      }
    } catch (e) {
      store.commit('setError', e, { root: true })
    }
  }

  // Удаление политики бронирования по ID
  async function removeReservationPolicy(objectId, policyId) {
    if (!objectId || !policyId) return false;
    try {
      return await Axios.delete(`/objects/${objectId}/policy/${policyId}`,);
    } catch (e) {
      store.commit('setError', e, { root: true })
    }
  }

  // Список избранных объектов
  async function getFavouriteObjects(type) {
    try {
      return await Axios.get(`/self/favorite-objects?type_id=${type}`,);
    } catch (e) {
      store.commit('setError', e, { root: true })
    }
  }

  // Добавление объекта в избранное
  async function addObjectToFavourites(objectId) {
    if (!objectId) return false;
    try {
      return await Axios.post(`/objects/${objectId}/fave`,);
    } catch (e) {
      store.commit('setError', e, { root: true })
    }
  }

  // Удаление объекта из избранного
  async function removeObjectFromFavourites(objectId) {
    if (!objectId) return false;
    try {
      return await Axios.post(`/objects/${objectId}/unfave`,);
    } catch (e) {
      store.commit('setError', e, { root: true })
    }
  }

  return {
    fetchListObjects, searchListObjects, getObjectById,
    updateObject, removeObject, massUpdateObjects, massSortObjects,
    generateQRCode, generateUniqueCode, getObjectBreadcrumbs,
    getObjectReservationPolicy, updateReservationPolicy, removeReservationPolicy,
    getFavouriteObjects, addObjectToFavourites, removeObjectFromFavourites,
  }
}

export function getListData() {
  const { searchListObjects, fetchListObjects } = requestAPI();
  const loading = ref(false);
  const search = ref('');
  const searchOld = ref('$$$'); // Для кэширования: если меняем строку поиска, то сбрасывает пагинацию
  const parentId = ref('root');
  const columns = [
    {
      title: 'Объект',
      slots: { customRender: 'name' },
    },
    {
      title: 'Код',
      dataIndex: 'code',
      key: 'code',
      className: 'text-break'
    },
    {
      title: 'Тип',
      slots: { customRender: 'objectType' },
    },
    {
      className: 'text-right',
      width: 100,
      slots: { customRender: 'actions', title: 'actionsTitle' },
    },
  ];
  const data = ref([]);
  const dataMode = ref('default'); // Формат данных: Обычный (default) или search
  const selectedRowKeys = ref([]);
  const expandedRowKeys = ref([]);
  const loadMoreKeys = ref({ key: 0 }); // Список разделов, у которых загружены подстраницы и количество этих страниц
  const pagination = reactive({ hideOnSinglePage: true });
  const isFilterSelected = ref(false);

  async function handleTableChange(tablePagination, filters, sorter) {
    console.log(filters);
    console.log(sorter);
    pagination.current = tablePagination.current;
    await fetchList();
  }

  async function fetchList() {
    try {
      loading.value = true;
      let res;
      // При изменении поиска или сбросе его - сбрасываем пагинацию
      if (unref(searchOld) !== unref(search)) {
        searchOld.value = unref(search);
        pagination.current = 1;
        pagination.total = 0;
      }
      if (unref(search)) {
        const payload = { search: encodeURI(unref(search)), page: pagination.current };
        if (unref(parentId) !== 'root') {
          payload.objectId = unref(parentId);
        }
        res = await searchListObjects(payload);
        data.value = res.data.filter((item) => item?.path?.length > 0);
        dataMode.value = 'search';
        isFilterSelected.value = true;

      } else {
        res = await fetchListObjects(unref(parentId), { page: pagination.current });
        dataMode.value = 'default';
        isFilterSelected.value = false;

        // Пройтись по списку expandedRowKeys и загрузить всех детей из списка.
        // Подумать ночью: как узнать сколько детей грузить, там же пагинация
        data.value = await traceAndExpand(res.data);
      }

      pagination.pageSize = +res?.meta?.perPage;
      pagination.total = +res?.meta?.total;
      pagination.current = +res?.meta?.currentPage;

    } catch (e) {
      console.error(e);
    } finally {
      loading.value = false;
    }
  }

  async function traceAndExpand(tree) {
    for (const node of tree) {
      if (expandedRowKeys.value.indexOf(node.id) !== -1) {
        await loadChildren(node);
        if (node.children !== undefined) {
          await traceAndExpand(node.children);
          // также грузить всех детей до конца пагинации!
        }
      }
    }
    return tree;
  }

  async function fetchChildren(parentId = 'root', page = 1) {
    try {
      return await fetchListObjects(parentId, { page });
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async function onExpandChange(expanded, node) {
    if (expanded) {
      expandedRowKeys.value.push(node.id);
      // Загрузка, если не загружено
      await loadChildren(node);
    } else {
      const indexToDelete = expandedRowKeys.value.indexOf(node.id);
      if (indexToDelete > -1) {
        expandedRowKeys.value.splice(indexToDelete, 1);
      }
      // Снять выделение с закрываемых
      // selectedRowKeys.value
      if (node.hasChildren && node.children?.length > 0) {
        node.children.forEach((child) => {
          const indexToDelete = selectedRowKeys.value.indexOf(child.id);
          if (indexToDelete > -1) {
            selectedRowKeys.value.splice(indexToDelete, 1);
          }
        });
      }
      node.children = undefined;
    }
  }

  async function loadChildren(node) {
    if (node.hasChildren && node.children === undefined) {
      node.loading = true;
      const res = await fetchChildren(node.id, 1);
      node.children = res.data;

      if (1 < res.meta.lastPage) {
        // Добавить еще одну loadMode
        const loadMoreLink = {
          id: 'load-more',
          parentId: node.id,
          parentNode: node,
          nextPage: 2,
        };
        node.children.push(loadMoreLink);
        if (loadMoreKeys.value[node.id] !== undefined) await loadMoreChildren(loadMoreLink);
      }
      node.loading = false;
    }
  }

  async function loadMoreChildren(linkMore) {
    const { parentId, nextPage, parentNode } = linkMore;
    const linkRowIndex = parentNode.children.findIndex((record) => record.id === 'load-more');

    const res = await fetchChildren(parentId, nextPage);

    // Удаляем ссылку Ещё... и добавляем загруженные данные
    parentNode.children.splice(linkRowIndex, 1);
    parentNode.children = parentNode.children.concat(res.data);
    loadMoreKeys.value[parentNode.id] = nextPage;

    if (nextPage < res.meta.lastPage) {
      // Добавить еще одну loadMode
      const loadMoreLink = {
        id: 'load-more',
        parentId,
        parentNode,
        nextPage: nextPage + 1,
      };
      parentNode.children.push(loadMoreLink);
      // Если уже открывали ранее и сохранено, загружаем снова
      if (loadMoreKeys.value[parentNode.id] > nextPage) await loadMoreChildren(loadMoreLink);
    }

  }

  watch(dataMode, () => {
    selectedRowKeys.value = [];
  });

  // Если будут фильтры, тут они будут применяться и сбрасываться
  async function applyFilters() {
    await fetchList();
  }

  async function resetFilters() {
    search.value = '';
    isFilterSelected.value = false;
    await applyFilters();
  }

  return {
    loading, columns, data, pagination,
    selectedRowKeys, expandedRowKeys, loadMoreKeys,
    search, dataMode, parentId,
    handleTableChange, onExpandChange, loadChildren, loadMoreChildren,
    fetchList,
    applyFilters, resetFilters, isFilterSelected
  }
}

export function getOneData() {

  const formInfo = ref({
    id: undefined,
    name: "",
    parentId: undefined,
    code: "",
    objectTypeId: undefined,
    reservationPolicyId: undefined,
    previewImage: undefined,
    qrCode: undefined,
    mapImage: undefined, // base64
    mapObjects: [],
    timezone: undefined,
  });

  const formAttributes = ref({ attributes: [] });

  const formMap = ref({ mapImage: '', mapObjects: [] });

  function renderObjectPath(path, showRoot = true, delimiter = ' > ') {
    const res = [];
    path.forEach((item) => {
      if (item.name === 'root') {
        if (showRoot) res.push('Верхний уровень');
      } else {
        res.push(item.name);
      }
    });
    return res.join(delimiter);
  }

  function resetFormInfo() {
    formInfo.value.name = '';
    formInfo.value.code = '';
    formInfo.value.objectTypeId = undefined;
  }

  return {
    formInfo, resetFormInfo,
    formAttributes,
    formMap,
    renderObjectPath,
  };
}
