<template>
  <a-modal v-model:visible="isVisible" title="Выберите раздел" :footer="null" @ok="isVisible = false">
    <p>Перемещаем {{ selectedItems?.length }}
      {{ stringHelper.declOfNum(selectedItems?.length, ['объект', 'объекта', 'объектов']) }}</p>
    <nav class="search-left-icon">
      <a v-if="search" class="ant-search-clear" @click="clearSearch">
        <close-circle-filled/>
      </a>
      <a-input-search v-model:value="search" placeholder="Поиск по названию" autocomplete="off"
                      @search="fetchList('root')"/>
    </nav>

    <div v-if="dataMode === 'search'">
      <object-search-table
          :compact-mode="true" :pagination="pagination" @change="handleTableChange"
          :data-source="dataSearchFormatted" :loading="loading">
        <template #actions="{ record }">
          <a-button v-if="record.id !== 'load-more'" size="large" type="primary"
                    :disabled="isSelected(record.id) || record?.isSelected"
                    @click="handleMoveToSection(record.id)">
            Выбрать
          </a-button>
        </template>
      </object-search-table>
      <div class="disabled-color-text" :style="{ padding: '0.5rem 0 2rem', margin: 0, clear: 'both' }">
        Запрещено перемещать в те разделы, которые являются дочерними для перемещаемых объектов.
      </div>
    </div>
    <a-table
        v-else class="w-100"
        :style="{ margin: '0.5rem 0 2.5rem' }" :columns="columns"
        size="middle" :pagination="{ hideOnSinglePage: true }"
        :data-source="dataFormatted" :loading="loading" row-key="id"
        :row-class-name="(record) => (record.id === 'load-more' ? 'row-load-more' : 'row-show-select-btn')"
        :expanded-row-keys="expandedRowKeys" @expand="onExpandChange"
        :locale="{ emptyText: 'Ничего не найдено' }" :show-header="false">
      <template #expandIcon="{ expanded, record, onExpand, expandable }">
          <span :style="{ marginRight: '0.5rem' }">
            <loading-outlined v-if="record.loading"/>
            <right-outlined v-else-if="(expandable || record.hasChildren)
                                    && !isSelected(record.id)"
                            :rotate="expanded ? 90 : 0"
                            @click="onExpand(record)"
            />
          </span>
      </template>
      <template #name="{ record }">
        <a class="navy-blue-color-text" v-if="record.id === 'load-more'" @click="loadMoreChildren(record)">Ещё...</a>
        <span v-else>{{ record.name }}</span>
      </template>
      <template #actions="{ record }">
        <a-button v-if="record.id !== 'load-more'" size="large" type="primary"
                  :disabled="isSelected(record.id)"
                  @click="handleMoveToSection(record.id)">
          Выбрать
        </a-button>
      </template>
    </a-table>

  </a-modal>
</template>

<script>
import { stringHelper } from "@/compositions/commonFunctions";
import {
  LoadingOutlined, RightOutlined, CloseCircleFilled,
} from '@ant-design/icons-vue';
import { computed, ref, unref } from "@vue/reactivity";
import { getListData, requestAPI } from "@/compositions/objects";
import { onMounted, watch } from "@vue/runtime-core";
import ObjectSearchTable from "@/components/admin/objects/ObjectSearchTable";

export default {
  name: 'ObjectMoveModal',
  props: {
    visible: Boolean,
    selectedItems: Array,
  },
  emits: ['proceed', 'update:visible'],
  setup(props, { emit }) {
    const isVisible = computed({
      get: () => props.visible,
      set: (value) => emit('update:visible', value)
    })

    const {
      loading,
      data, dataMode,
      search, pagination, handleTableChange,
      fetchList,
      expandedRowKeys,
      onExpandChange,
      loadChildren, loadMoreChildren,
    } = getListData();

    const columns = [
      {
        slots: { customRender: 'name' },
      },
      {
        className: 'text-right',
        slots: { customRender: 'actions', title: 'actionsTitle' },
      },
    ];
    const rootLevel = ref({});
    // Выводим только верхний элемент, он разворачивается у нас
    const dataFormatted = computed(() => [unref(rootLevel)] || []);
    // Помечаем строки, которые содержатся в списке перемещаемых, как заблокированные
    const dataSearchFormatted = computed(() => {
      const dataClear = [];
      (unref(data) || []).forEach((group) => {
        const pathIds = (group?.path || []).map(item => item.id);
        if (arrayIntersect(pathIds, props.selectedItems || []).length !== 0) {
          group.objects = group.objects.map((item) => ({ ...item, isSelected: true }));
        }
        dataClear.push(group);
      });
      return dataClear;
    });

    onMounted(async () => {
      // await loadData();
    });

    watch(isVisible, (open, close) => {
      if (open && !close) {
        clearSearch();
        rootLevel.value = { name: 'Подождите...' };
        loadData();
      }
    });

    async function loadData() {
      // Добавим в список верхний уровень
      loading.value = true;
      expandedRowKeys.value = [];
      const res = await requestAPI().getObjectById('root');
      rootLevel.value = { ...res, name: 'Верхний уровень' };
      if (expandedRowKeys.value.indexOf(res?.id) === -1) expandedRowKeys.value.push(res?.id);
      await loadChildren(rootLevel.value);
      loading.value = false;
    }

    function handleMoveToSection(recordId) {
      emit('proceed', recordId);
    }

    function clearSearch() {
      search.value = '';
      pagination.current = 1;
      pagination.total = 0;
      dataMode.value = 'default';
    }

    function isSelected(id) {
      return (unref(props.selectedItems) || []).indexOf(id) !== -1;
    }

    function arrayIntersect(array1, array2) {
      return array1.filter(value => array2.includes(value));
    }

    return {
      stringHelper,
      isVisible, search, columns,
      data, dataFormatted, dataSearchFormatted,
      dataMode, loading, fetchList, pagination, handleTableChange,
      expandedRowKeys, onExpandChange, isSelected,
      handleMoveToSection, loadMoreChildren, clearSearch,
    }
  },
  components: {
    ObjectSearchTable,
    LoadingOutlined, RightOutlined, CloseCircleFilled,
  },
}
</script>
